/* Coolors Exported Palette - coolors.co/de6b48-5eb1bf-e5b181-e0a890-f4b9b2 */

export const  COLORS = {
  RED: '#995C4C',
  DARK_BLUE: '#36667C',
  BLUE:  '#659188',
  LIGHT_BLUE: '#4C87A2',
  LAVENDER: '#9B919C',
  GREY: '#9A9897'
}
